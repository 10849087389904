<template>
  <v-app-bar class="justify-start" :floating="false" color="surface-strong">
    <v-app-bar-nav-icon @click="useNav().toggleMenu" />

    <v-app-bar-title>
      <p data-testid="app-title" class="text-body-2 opacity-70">{{ title }}</p>
      <span data-testid="page-title">{{ $route.meta.title }}</span>
    </v-app-bar-title>

    <v-spacer />

    <SfiAppSwitcher class="mr-1" :apps="session.applications" />
    <SfiUserMenu class="mr-1" :user="session" @sign-out="signOut()" />
  </v-app-bar>
</template>

<script setup lang="ts">
import { signOut } from '@/api/authentication'
import { useSessionStore } from '@/stores/session'
import { useNav } from '@/composables/nav'
import { storeToRefs } from 'pinia'

const title = import.meta.env.VITE_APP_TITLE

const sessionStore = useSessionStore()
const { session } = storeToRefs(sessionStore)
</script>

<style>
.toolbar__title {
  flex: 0 1 auto !important;
}
</style>
