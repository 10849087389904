import { getAccessToken, isUserLoggedIn } from '@/api/authentication'
import { useSessionStore } from '@/stores/session'
import type { RouteLocationNormalized, Router } from 'vue-router'

export function registerGuard(router: Router) {
  router.beforeEach(async (to: RouteLocationNormalized) => {
    if (to.meta.requiresAuth) {
      if (!(await isUserLoggedIn())) {
        return { name: 'loginError' }
      }

      await useSessionStore().setToken(await getAccessToken())
      if (!useSessionStore().isLoaded) {
        return { name: 'permissionError' }
      }
    }

    return true
  })
}
