import type { init } from '@/lib/flags'
import type { Session } from '@/types/Session'
import { getSession } from '@/api/session'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useSessionStore = defineStore('user', () => {
  const token = ref<string | null>(null)
  const session = ref<Session>({
    userId: '',
    userName: '',
    email: '',
    roles: [],
    permissions: [],
    applications: [],
  })
  const updating = ref(false)

  let ld: ReturnType<typeof init>

  async function setToken(value: string | null) {
    token.value = value
    if (!updating.value) {
      updating.value = true
      session.value = await getSession()
      updating.value = false
    }
    return !!session.value.userId
  }

  return {
    isLoaded: computed(() => !!session.value.userId),
    hasConfigAccess: computed(() => {
      return !!(
        session.value.permissions.includes('Operations.ConfigureShortCodes') ||
        session.value.permissions.includes(
          'Operations.ConfigureWorkstreamTimers',
        )
      )
    }),
    hasPermission(permission: string) {
      return session.value.permissions.includes(permission)
    },
    setToken,
    ld,
    session,
  }
})
