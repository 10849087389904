import {
  mdiAccountGroup,
  mdiAccountReactivate,
  mdiAccountTie,
  mdiAlertCircleOutline,
  mdiCalendarRange,
  mdiCancel,
  mdiChartLine,
  mdiCheck,
  mdiChevronDown,
  mdiChevronUp,
  mdiClockOutline,
  mdiClose,
  mdiContentSave,
  mdiFormatListText,
  mdiHeadset,
  mdiHeartPlus,
  mdiHistory,
  mdiHome,
  mdiMapMarker,
  mdiNoteEdit,
  mdiPalette,
  mdiPhone,
  mdiShieldAlert,
  mdiTagText,
  mdiWrench,
} from '@mdi/js'
import { Theme } from '@simplifi/components'
import type { VuetifyOptions } from 'vuetify/lib/framework.mjs'

export const vuetifyOptions: VuetifyOptions = {
  defaults: {
    VAutocomplete: {
      density: 'comfortable',
      color: 'secondary',
    },
    VBtn: {
      flat: true,
      variant: 'tonal',
    },
    VDialog: {
      persistent: true,
      maxWidth: '50rem',
    },
    VMenu: {
      transition: { duration: 150 },
    },
    VProgressCircular: {
      color: 'secondary',
      indeterminate: true,
    },
    VSelect: {
      density: 'comfortable',
      color: 'secondary',
    },
    VSwitch: {
      color: 'primary',
      inset: true,
    },
    VTooltip: {
      activator: 'parent',
      location: 'top',
      openDelay: '200',
      contentClass: 'shadow',
    },
  },
  icons: {
    aliases: {
      history: mdiHistory,
      up: mdiChevronUp,
      down: mdiChevronDown,
      timer: mdiClockOutline,
      tick: mdiCheck,
      cross: mdiClose,
      tag: mdiTagText,
      save: mdiContentSave,

      // Nav
      home: mdiHome,
      profile: mdiAccountTie,
      policies: mdiNoteEdit,
      portals: mdiPalette,
      phone: mdiPhone,
      users: mdiAccountGroup,
      graph: mdiChartLine,

      // Job
      trade: mdiWrench,
      mapMarker: mdiMapMarker,
      calendar: mdiCalendarRange,
      abandoned: mdiCancel,
      returnVisit: mdiAccountReactivate,
      emergency: mdiAlertCircleOutline,
      stream: mdiFormatListText,

      // Customer
      vulnerability: mdiShieldAlert,
      healthAndSafety: mdiHeartPlus,
      complaints: mdiHeadset,
    },
  },
  theme: {
    themes: {
      [Theme.Light]: {
        colors: {
          // Job Statuses
          Pending: '#e6203a',
          Accepted: '#2196f3',
          AutoDeploying: '#2196f3',
          Unavailable: '#e6203a',
          InProgress: '#ff9800',
          Cancelled: '#e6203a',
          Completed: '#4caf50',

          // Vulnerability / Health Levels
          Low: '#69a1be',
          'Low-variant': '#d0dfe7',
          Medium: '#ff9800',
          'Medium-variant': '#ffebcc',
          High: '#e6203a',
          'High-variant': '#fccfcf',
        },
      },
      [Theme.Dark]: {
        colors: {
          // Job Statuses
          Pending: '#e6203a',
          Accepted: '#2196f3',
          AutoDeploying: '#2196f3',
          Unavailable: '#e6203a',
          InProgress: '#ff9800',
          Cancelled: '#e6203a',
          Completed: '#4caf50',

          // Vulnerability / Health Levels
          Low: '#69a1be',
          'Low-variant': '#69a1be',
          Medium: '#ff9800',
          'Medium-variant': '#ff9800',
          High: '#e6203a',
          'High-variant': '#e6203a',
        },
      },
    },
  },
}
