import router from '@/router'
import { useSessionStore } from '@/stores/session'
import type { LDMultiKindContext } from 'launchdarkly-js-client-sdk'
import { ldInit, useLDFlag } from 'launchdarkly-vue-client-sdk'

export function init() {
  const { session, isLoaded } = useSessionStore()

  if (!isLoaded) {
    throw new Error('Attempted to initialize LaunchDarkly without a session')
  }

  const context: LDMultiKindContext = {
    kind: 'multi',
    user: {
      anonymous: false,
      key: `${session.userId}`,
      name: session.userName,
      email: session.email,
    },
  }

  try {
    const [, client] = ldInit({ context })
    client.on('failed', (err) => {
      console.error('LD Failed to init', err)
      router.push({ name: 'permissionError' })
    })
    client.on('error', (err) => {
      console.error('LD Errored on init', err)
      router.push({ name: 'permissionError' })
    })

    useSessionStore().ld = client
    return client
  } catch (error) {
    console.error('LD Failed to init', error)
  }

  router.push({ name: 'permissionError' })
}

export function useFlag(flagName: string, defaultValue = false) {
  try {
    return useLDFlag(flagName, defaultValue)
  } catch {
    return defaultValue
  }
}

export const flag = {
  appAccess: () => useFlag('wip-246-progress-app'),
  siDashboard: () => useFlag('wip-647-si-dashboard'),
  auditingDashboard: () => useFlag('wip-661-operations-auditing-dashboard'),
}
