import api from '@/api/api'
import router from '@/router'
import { CustomNavigationClient } from '@/router/navigation-client'
import type { AuthenticationConfig } from '@/types/Authentication'
import type {
  AuthenticationResult,
  Configuration,
  EndSessionRequest,
  EventMessage,
  RedirectRequest,
} from '@azure/msal-browser'
import { EventType, PublicClientApplication } from '@azure/msal-browser'
import { updateGlobalContext } from '@simplifi/logging'

export const REDIRECT_PATH_STORAGE_KEY = 'identity-redirect-path'

let auth: PublicClientApplication
let loginRequest: RedirectRequest

export async function initializeAuthentication() {
  const apiAuthConfig = await getAuthConfig()
  const authConfig: Configuration = {
    auth: {
      clientId: apiAuthConfig.clientId,
      authority: apiAuthConfig.authority,
      knownAuthorities: [apiAuthConfig.knownAuthority],
      redirectUri: `${window.location.protocol}//${window.location.host}/identity/callback`,
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
  }
  loginRequest = {
    scopes: [apiAuthConfig.defaultScope],
  }

  auth = new PublicClientApplication(authConfig)
  auth.initialize()

  // Allows MSAL library to interact with VueRouter
  const navigationClient = new CustomNavigationClient(router)
  auth.setNavigationClient(navigationClient)

  const accounts = auth.getAllAccounts()
  if (accounts.length > 0) {
    auth.setActiveAccount(accounts[0])
  }

  auth.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult
      const account = payload.account
      auth.setActiveAccount(account)
    }
  })
  return auth
}

export const signIn = async () => {
  await auth.loginRedirect(loginRequest)
}

export const signOut = () => {
  const logoutRequest: EndSessionRequest = {
    account: auth.getActiveAccount(),
    postLogoutRedirectUri: `${window.location.origin}/`,
  }
  auth.logoutRedirect(logoutRequest)
}

export const isUserLoggedIn = (): Promise<boolean> => {
  return auth
    .handleRedirectPromise()
    .then(() => {
      const accounts = auth.getAllAccounts()
      if (accounts.length > 0) {
        return true
      }
      localStorage.setItem(REDIRECT_PATH_STORAGE_KEY, window.location.pathname)
      // User is not logged in and attempting to access protected route. Log them in.
      return auth.loginRedirect(loginRequest).then(() => {
        return true
      })
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err)
      return false
    })
}

export const getAccessToken = async (): Promise<string | null> => {
  if (!auth) {
    return null
  }
  try {
    if (!auth.getActiveAccount()) {
      return null
    }
    const result = await auth.acquireTokenSilent(loginRequest)
    updateGlobalContext(result.accessToken)
    return result.accessToken
  } catch (error) {
    if (
      error instanceof Error &&
      error.name === 'InteractionRequiredAuthError'
    ) {
      signIn()
    }
    throw error
  }
}

export const getAuthConfig = async (): Promise<AuthenticationConfig> => {
  const { data } = await api.get<AuthenticationConfig>(
    'authentication/configuration',
  )
  return data
}

api.interceptors.request.use(async (config) => {
  if (config.headers && !config.headers.Authorization) {
    config.headers.Authorization = `bearer ${await getAccessToken()}`
  }
  return config
})
