import { defineStore } from 'pinia'
import type { ComputedRef } from 'vue'
import { computed, reactive, ref } from 'vue'

/**
 * Add new request types here to keep track of them.
 * You can then use the store to show a loading indicator when any of these requests are active.
 * e.g. requests.Search.active
 */
// Typescript enums count as functions for code coverage, so we ignore them.
/* c8 ignore start */
export enum RequestType {
  All = 'All', // Used in the api service to track all requests and shouldn't be used directly.
  Main = 'Main',
  Clients = 'Clients',
  Jobs = 'Jobs',
  Trades = 'Trades',
  Workstream = 'Workstream',
  WorkstreamTimers = 'WorkstreamTimers',
  VisitOutcomes = 'VisitOutcomes',
  ShortCodes = 'ShortCodes',
  Users = 'Users',
  SiteInvestigation = 'SiteInvestigation',
  Complaints = 'Complaints',
  Auditing = 'Auditing',
  DelayCodes = 'DelayCodes',
  TradeCategories = 'TradeCategories',
}
/* c8 ignore stop */

type RequestCounts = {
  [K in RequestType]: {
    count: ComputedRef<number>
    increment: (id?: string) => void
    decrement: (id?: string) => void
    active: ComputedRef<boolean>
    ids: ComputedRef<string[]>
  }
}

export const useRequestsStore = defineStore('requests', () => {
  const counts = reactive<Record<string, number>>({})
  const ids = ref<string[]>([])

  function increment(name: RequestType, id?: string) {
    counts[name] = (counts[name] || 0) + 1
    if (id) {
      ids.value.push(id)
    }
  }

  function decrement(name: RequestType, id?: string) {
    counts[name] = (counts[name] || 0) - 1
    if (id) {
      ids.value = ids.value.filter((i) => i !== id)
    }
  }

  const requests: RequestCounts = Object.values(RequestType).reduce(
    (acc, type) => ({
      ...acc,
      [type]: {
        count: computed(() => counts[type]),
        increment: (id) => increment(type, id),
        decrement: (id) => decrement(type, id),
        active: computed(() => counts[type] > 0),
        ids: computed(() => ids.value),
      } satisfies RequestCounts[typeof type],
    }),
    {} as RequestCounts,
  )

  return {
    counts,
    ...requests,
  }
})
